#mobile-wrapper{

    min-height: 100vh;

}
#mobile-header{

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5% 0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    height: 7vh;

}
#mobile-root{

    display: flex;
    flex-direction: column;
    background-color: #D3E9E2;
    overflow-y: auto;
    min-height: 81vh;

}
#mobile-ownLogo,#mobile-customerLogo{

    width: auto;
    height: 100%;

}
#mobile-container{

    margin: 1.2% 0;
    background-color: white;
    height: 45%;
    border-radius: 6px;

}
#mobile-top-container{

    height: 50%;
    display: flex;
    justify-content: space-between;

}
#rectangle{

    width: 45%;
    border-radius: 6px;
    background-image: url('../public/img/rectangle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    padding: 0 2%;

}
#mobile-place-temp-humid-container{

    height: 80%;
    position: relative;
    top: 10%;

}
#mobile-place-container{

    font-size: 0.6rem;
    color: white;
    text-align: left;

}
#mobile-heading-container{

    margin-bottom: 4%;

}
#mobile-heading-container > div{

    width: 80%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
#mobile-heading-container > div > img{

    width: 33%;

}
#mobile-heading-container > div > p{

    font-size: 0.75rem;
    margin: 0;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

}
#mobile-temp-container,#mobile-humid-container{

    width: 50%;
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 5%;

}
#mobile-temp,#mobile-humid{

    font-size: 0.875rem;
    color: white;
    font-weight: 600;

}
#mobile-temp-icon,#mobile-humid-icon{

    width: 60%;

}
#mobile-aqi-container{

    width: 23%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 0.7rem;

}
#mobile-aqi-condition-image{

    width: 32%;

}
#mobile-aqi-container > p{

    margin: 0;
    font-weight: 600;

}
#mobile-bottom-container{

    height: 45%;
    /* margin-top: 5%; */

}
#mobile-minor-details{

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin: 3% 0;

}
#mobile-type{

    margin: 0;
    font-weight: 500;

}
#mobile-value{

    font-weight: 800;
    font-size: 1.1rem;
    margin: 0;

}
#mobile-unit{

    font-size: 0.6rem;
    margin: 0;

}
#mobile-footer{

    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5%;

}
#mobile-partner-colab-container{

    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50%;

}
#mobile-aqi-scale{

    /* width: 80%; */
    height: 35%;
    margin-bottom: 10px;

}
#mobile-partner-container{

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}
#mobile-colab-container{

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

}
#mobile-colabIcon, #mobile-partnerIcon{

    width: fit-content;
    height: 60%;
    margin-bottom: 3px;

}
#mobile-partnerText{

    font-size: 0.65rem;
    font-weight: 400;
    margin: 0;

}
#mobile-colabText{

    font-size: 0.7rem;
    font-weight: 400;
    margin: 0;

}

/* Mobile_AQI_Display */

#aqi-container{

    color: white;
    border-radius: 15px;
    width: 75%;
    height: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
#aqi-container > div > p{

    margin: 0;

}
#data{

    font-size: 1.35rem;
    font-weight: 500;

}
#timepass{

    height: 5%;
    
}

@media screen and (min-width: 256px) and (max-width: 320px) {
   
#mobile-header{

    height: 12vh;
    padding: 0.5%;

}
#mobile-ownLogo{

    /* width: 15%; */
    height: 80%;

}
#mobile-root{

    min-height: 70vh;

}
#mobile-top-container{

    height: 40%;

}
#mobile-heading-container > div > p{

    font-size: 0.6rem;

}
#mobile-heading-container > div > img{

    width: 25%;

}
#mobile-temp-container,#mobile-humid-container{

    width: 50%;

}
#mobile-temp-icon, #mobile-humid-icon{

    width: 45%;

}
#mobile-temp,#mobile-humid{

    font-size: 0.55rem;

}
#Celcius{

    font-size: 8px !important;

}
#aqi-container{

    width: 65%;
    height: 45%;
    border-radius: 10px;

}
#aqi-container > div > p{

    font-size: 0.55rem;

}
#mobile-aqi-container > p {

    font-size: 0.5rem;

}
#data{

    font-size: 0.9rem;

}
#mobile-minor-details{

    margin: 1.5%;

}
#mobile-type{

    font-size: 0.4rem;

}
#mobile-value{

    font-size: 0.5rem;

}
#mobile-unit{

    font-size: 0.2rem;

}
#mobile-footer{

    height: 12vh;
    padding: 1%;

}
#mobile-aqi-scale{

    height: 70%;
    margin-bottom: 0px;

}
#mobile-colabIcon, #mobile-partnerIcon{

    height: 80%;

}
#mobile-colabText,#mobile-partnerText{

    font-size: 0.3rem;

}
#timepass{

    height: 8px !important;

}

}

@media screen and (min-width: 768px) {
    
#mobile-heading-container > div > p{

    font-size: 1.3rem;

}

#mobile-temp-container, #mobile-humid-container{

    width: 50%;

}
#mobile-temp-icon, #mobile-humid-icon{

    width: 65%;

}
#mobile-temp, #mobile-humid{

    font-size: 1.6rem;
    font-weight: 600;

}
#Celcius{

    font-size: 1.3rem !important;

}

/* Mobile_AQI_Display */

#aqi-container{

    border-radius: 25px;

}
#aqi-container > div > p{

    font-size: 1.1rem;

}
#mobile-heading-container > div > img{

    width: 35%;

}
#data{

    font-size: 2.3rem;

}
#mobile-aqi-container > p{

    font-size: 1.4rem;
    font-weight: 500;

}
#mobile-type{

    font-size: 1.4rem;

}
#mobile-value{

    font-size: 2rem;

}
#mobile-unit{

    font-size: 0.9rem;

}
#mobile-aqi-scale{

    width: 70%;
    margin: 0;

}
#mobile-colabText,#mobile-partnerText{

    font-size: 0.98rem;

}

}

@media screen and (min-width: 910px) {

#mobile-heading-container > div > p{

    font-size: 1.6rem;

}
#mobile-temp-container,#mobile-humid-container{

    width: 50%;

}
#mobile-temp-icon,#mobile-humid-icon{

    width: 55%;

}
#mobile-temp,#mobile-humid{

    font-size: 1.8rem;

}
#aqi-container{

    width: 60%;
    height: 42%;

}
#aqi-container > div > p{

    font-size: 1.4rem;

}
#data{

    font-size: 3rem;

}
    
}
