body{

    font-family: Poppins;

}

.wrapper{

    height: 100vh;

}

.root{

    height: 75%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;

}
.root .new-parent:nth-child(even) {
    background: linear-gradient(315deg, #CDFCFF, #FFFFFF);
}
.root .parent:nth-child(even) {
    background: linear-gradient(315deg, #CDFCFF, #FFFFFF);
}

#header {

    position: relative;
    height: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}
  
#logo {

    display: flex;
    justify-content: center;
    align-self: center;
    padding-right: 7%;
    width: 50%;
    height: 80%;

}
#ownLogo{

    display: block;

}
  
#footer {
    position: relative;
    height: 15%;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-evenly;
    padding: 1%;
    width: 100%;
}

#partner-container{

    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}
#partnerIcon{

    height: 80%;
    width: fit-content;
    margin-bottom: 0.5rem;
}
#partnerText,#colabText,#pmessage{

    font-size: 0.85rem;
    font-weight: 400;

}
#alt-x{

    display: flex;
    justify-content: center;
    flex-direction: column;

}

#x{

    display: flex;
    flex-direction: column;
    justify-content: center;

}
#alt-x > section{

    font-size: 1rem;

}
#alt-x > p{

    margin: 0;
    font-size: 1.7rem;
    font-weight: 700;

}
#x > section{

    font-size: 1.2rem;
    font-weight: 600;

}
#x > p{

    font-size: 2.3rem;
    margin: 0;
    font-weight: 500;

}
#holder-original{

    width: 25%;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

}
#holder{

    width: 25%;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

}

#aqi_summary{

    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;

}
#aqi_scale_img{

    width: 48%;
    margin: auto;
    display: block;

}
#own-container{

    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

}
#colabIcon{
    height: 80%;
    margin-bottom: 0.5rem;
}
#colabText{

    text-align: right;

}
#test-original{

    height: 15vh;
    width: 15vh;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    left: -1.5rem;

}
#test{

    height: 15vh;
    width: 15vh;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    left: -2.5rem;

}

#logo {
    width: 30%;
}

.parent{

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: linear-gradient(45deg, #E6FFEC, #FFFFFF);
    padding: 1%;
    margin: 0 ;

}
.heading-container{

    height: 5%;

}
#new-heading-container > h2{

    padding-top: 1%;
    font-size: 1.8rem;

}
.heading{

    text-align: center;
    font-size: 1rem;

}
#location-container{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin: 0 auto;

}
#outdoor, #indoor{

    width: 20%;
    margin-right: 2%;

}
#location-container > p{

    font-weight: 300;
    margin: 0;
    font-size: 2.1rem;

}

.Major_Details{

    height: 30%;
    display: flex;
    justify-content: center;

}
.paging-parent{

    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1%;

}

.paging-heading-container{

    height: 8%;

}
.paging-heading{

    font-size: 1.8rem;
    font-weight: 700 !important;
    text-align: center;

}

.paging-major{

    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.paging-major > *{

    height: 100%;

}

#paging-temp-humid-display{

    width: 20%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 10px;

}
#paging-temp-display,#paging-humid-display{

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

}
#paging-temp,#paging-humidity{

    font-size: 1.5rem;

}

#paging-temp_icon,#paging-humid_icon{

    width: 23%;

}

#paging-aqi-res-display{

    width: 80%;
    margin: 1%;
    display: flex;
    justify-content: center;

}

#paging-aqi-display{

    width: 50%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 2rem;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);

}
#paging-aqi{

    width: 25%;

}
#paging-res {

    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 10px;

}
#container{

    height: 50%;
    width: 30%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 0 30px 30px 0;
    text-align: center;

}

#paging-actual_data{

    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;

}

.paging-minor{

    height: 60%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 0.3fr));
    justify-content: center;
    align-items: center;
    gap: 1%;

}
#paging-pm25{

    /* width: 100%; */
    height: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 10px;
    margin: 1%;
    padding: 0.5% 1%;

}
#paging-pm25-img{

    width: 60%;

}
#paging-left,#paging-right{

    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

}

.paging-type{

    font-size: 1.8rem;
    margin: 0;

}
.paging-val,.paging-unit{

    margin: 0;
    font-size: 1.5rem;

}
.paging-val{

    font-size: 1.8rem;
    font-weight: 700;
    text-shadow: 1px 1px 2px black;

}

#calender_img{

    display: block !important;
    width: 20% !important;

}
.new-parent{

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: linear-gradient(45deg, #E6FFEC, #FFFFFF);
    padding: 1%;
    margin: 0;

}
#new-heading-container{

    text-align: center;
    font-size: 1.2rem;
    font-weight: 500 !important;

}

.new-Major-Details{

    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;

}

.new-Major-Details > *{

    height: 100%;
    margin: 0 1%;

}

#new-temp-icon, #new-humid-icon{

    width: 17%;

}
#new-temp{

    font-size: 2rem;

}
#new-aqi-res-display{

    width: 85%;
    display: flex;
    justify-content: space-evenly;
    margin-left: 1%;

}

#new-temp-humid-display{

    width: 15%;
    margin: auto 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-bottom: solid 5px #00b02c00;
    border-radius: 10px;
    color: #333;

}

#new-aqi-display{

    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-bottom: solid 5px #00b05000;
    border-radius: 10px;
    color: #333;
    margin-right: 1%;

}

#new-res-display{

    width: 23%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-bottom: solid 5px #00b05000;
    border-radius: 2%;
    color: #333;
    font-size: 1.3rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
#new-aqi-image-original{

    width: 50%;

}
#new-aqi-image{

    width: 50%;

}

#new-res-image{

    width: 50%;

}

.new-Minor-Details{

    height: 60%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 0.3fr));
    justify-content: center;
    align-items: center;
    gap: 1%;

}
#new-pm25-container{

    height: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 8px;

}

#top, #bottom{

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2%;

}

#top > *, #bottom > *{

    margin: 0;

}

.new-value{

    text-shadow: 1px 1px 1px black;
    font-size: 1.5rem;
    font-weight: 600;

}

.new-type{

    text-transform: uppercase;
    font-size: 1.5rem;

}

#bottom{

    font-size: 1rem;
    font-weight: 500;

}
#time{

    text-align: center;
    font-size: 1.2rem;

}

#new-pm25-image{
    
    width: 30%;
    
}

#unit{

    font-size: 0.8rem;

}

#temp-humid-display{

    width: 30%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-bottom: solid 5px #00b05000;
    border-radius: 2%;
    color: #333;
    border-radius: 0.25rem;

}

#temp p{

    margin: 0;
    text-align: center;
    font-size: 1.5rem;

}
#temp_icon,#humid_icon{

    width: 25%;

}

#humidity p{

    margin: 0;
    text-align: center;
    font-size: 2rem;

}

#aqi-res-display{

    height: 65%;
    width: 70%;
    font-size: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
#aqi-display{

    height: 75%;
    width: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-bottom: solid 5px #00b05000;
    padding: 10% 0;

}
#aqi-display div{

    padding: 0 10%;
    border-radius: 0 16px 16px 0;

}
#aqi-display section{

    font-size: 24px;
    font-weight: 700;

}

#res{

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 10px;

}
#major-res{

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;   
    border-radius: 10px;

}
#test-res{

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;   
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-bottom: solid 5px #00b05000;

}
#test-res #condition-icon{

    width: 50%;

}

#condition-icon{

    margin: 0 auto;
    width: 100%;

}
#res p{

    margin: 0;
    text-align: center;

}

.Minor_Details{

    height: 60%;
    display: flex;
    justify-content: flex-start;

}

#pm25_container{

    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    border-radius: 0.25rem;
    margin: 0 2%;

}
#left-hand{

    padding-top: 10%;

}
#pm25_img{

    display: block;
    margin: auto auto;

}
.type{

    font-size: 1.3rem;
    font-weight: 600;

}

.val{

    text-align: center;
    font-size: 1.7rem;
    font-weight: 800;
    text-shadow: 0px 0px 1px #222;

}
.unit{

    font-size: 0.9rem;

}
@media screen and (min-width: 256px) and (min-height: 128px) and (max-width: 320px) and (max-height: 260px){
    #header{

        height: 15%;

    }
    .root{

        height: 70%;

    }
    p{

        font-size: 23%;
        margin: 0;

    }
    #location-container > p{

        font-size: 0.4rem;

    }
    #new-heading-container > h2{

        font-size: 0.5rem;

    }
    #alt-x > section{

        font-size: 0.3rem;

    }
    #alt-x > p{

        font-size: 0.4rem;

    }
    #condition-icon{

        width: 30%;

    }
    #holder-original{

        width: 40%;

    }
    #time{

        font-size: 0.3rem;

    }
    #footer{

        height: 15%;

    }
    #pmessage{

        font-size: 0.25rem;
        margin: 0;

    }
    #partner-container{

        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }
    #colabText,#partnerText{

        font-size: 0.2rem;

    }
    #colabIcon,#partnerIcon{

        margin: 0.1rem;

    }
}
@media screen and (min-width: 768px) and (orientation: portrait) {

    #new-res-display{

        font-size: 0.8rem;

    }

    #new-pm25-image{

        width: 30%;

    }
    #new-value{

        font-size: 1.5rem;

    }
    
}
@media screen and (min-width: 1024px){

    .parent{

        margin: 0 0.3rem;

    }
    .paging-heading-container{
        
        height: 5%;
        margin-bottom: 2%;

    }
    .paging-heading{

        font-size: 1.3rem;
        font-weight: 500 !important;

    }
    .paging-major{

        height: 35%;

    }
    .paging-minor{

        height: 50%;

    }
    #paging-temp-humid-display{

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

    }
    #paging-temp_icon,#paging-humid_icon{

        width: 15%;

    }
    #paging-temp,#paging-humidity{

        font-size: 1.2rem;

    }
    #paging-time{

        font-size: 1rem;
        text-align: center;

    }
    #paging-res{

        width: 25%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    #paging-aqi-display{

        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
        border-bottom: solid 5px #00b05000;
        border-radius: 2%;
        color: #333;
        margin-right: 1%;

    }
    #paging-condition-icon{

        width: 70%;

    }
    .paging-type,.paging-unit{

        font-size: 1rem;

    }
    .paging-val{

        text-align: center;
        font-size: 1.5rem;

    }
    #paging-pm25-img{

        width: 40%;

    }
    .new-root{

        height: 75%;

    }
    .heading{

        font-size: 1rem;

    }
    #location-container > p{

        font-size: 1.8rem;

    }
    .Major_Details{

        height: 40%;
        flex-direction: column-reverse;

    }
    #temp-humid-display{

        width: 100%;
        display: flex;
        justify-content: space-between;
        border: none;
        box-shadow: none;

    }
    #aqi-res-display{

        width: 100%;
        height: 100%;

    }
    #aqi-display,#res{

        box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
        border-radius: 50px;

    }
    #aqi-display{

        height: 33%;
        width: 60%;
        padding: 0;

    }
    #aqi-display div{

        width: 96%;
        margin: 0 auto;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    #aqi-display div section{

        font-size: 1.2rem;
        font-weight: 500;

    }
    #time{

        font-size: 0.7rem;

    }
    #holder-original{

        width: 30vh;
        height: 11vh;

    }
    #holder{

        width: 30vh;
        height: 12vh;

    }
    #test{

        height: 17vh;
        width: 16vh;
        left: -2rem;

    }
    #x > section{

        font-size: 0.8rem;
        font-weight: 500;

    }
    #x > p{

        font-size: 2rem;

    }
    #actual_data{

        font-size: 1.5rem;
        font-weight: 500 !important;
        margin: 0;

    }
    #res p{

        font-size: 1rem;
        font-weight: 600;

    }
    #major-res p{

        margin: 0;
        text-align: center;
        font-size: 0.6rem;
    
    }
    #test-res p{

        margin: 0;
        text-align: center;
        font-size: 1rem;

    }
    #temp-display, #humid-display{

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    #temp,#humidity{

        font-size: 0.95rem;

    }
    #partnerText{

        font-size: 0.7rem;

    }
    #partnerIcon{

        height: 40%;
        margin-bottom: 0.5rem;
    }
    #colabIcon{
        height: 50%;
        margin-bottom: 0.5rem;
    }
    .paging-unit{

        font-size: 1.1rem;

    }
    .Minor_Details{

        margin-top: 2%;
        height: 50%;
        flex-direction: column;

    }
    #pm25_container{

        box-shadow: none;
        border: none;
        border-radius: 0;
        justify-content: space-between;
        margin: 0;

    }
    #new-temp{

        font-size: 1.4rem;

    }
    #left-hand,#right-hand{

        width: 96%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;

    }
    #left-hand > *,#right-hand > *{

        margin: 0;

    }
    #pm25_img{

        width: 30%;

    }
    .type{

        font-size: 0.9rem;
        font-weight: 500;

    }
    .unit{

        font-size: 0.75rem;

    }
    .val{

        font-size: 1.1rem;

    }
    .new-Major-Details > *{

        margin: 0 auto;

    }
    #new-aqi-res-display{

        width: 100%;

    }
    #new-res-display{

        font-size: 1rem;

    }
    #new-pm25-container{

        margin: 6px;

    }

    #new-pm25-image{

        padding-top: 3%;
        width: 20%;

    }

    .new-value{

        font-size: 1.4rem;

    }
    .new-type{

        text-transform: uppercase;
        font-size: 1.2rem;

    }
    .new-unit{

        font-size: 1.15rem;
        font-weight: 300;

    }

    #aqi_scale_img{

        width: 60%;

    }
    #footer{

        padding: 1.5% 1%;

    }
    
}
@media screen and (min-width: 1388px){

    .root{

        height: 80%;

    }
    #footer{

        height: 10%;

    }
    .parent{
        
        box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    
    }
    .paging-parent{

        height: 80%;

    }
    .paging-heading-container{

        height: 5%;

    }
    .paging-heading{

        font-size: 1.5rem;
        font-weight: 600 !important;

    }
    .paging-major{

        height: 30%;

    }
    #paging-temp-humid-display{

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 15%;

    }
    #paging-temp_icon,#paging-humid_icon{

        width: 18%;

    }
    #paging-temp,#paging-humidity{

        font-size: 1.2rem;

    }
    #paging-aqi-res-display{

        width: 85%;
        justify-content: space-evenly;
        margin: 0;

    }
    #paging-aqi-display{

        width: 80%;

    }
    #paging-condition-icon{

        width: 70%;

    }
    #paging-res{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 15%;
        font-size: 1.4rem;

    }
    #paging-pm25{

        height: 50%;

    }
    #paging-pm25-img{

        width: 50%;

    }
    .paging-type,.paging-unit{

        font-size: 1.3rem;

    }
    #x > section{

        font-size: 0.9rem;
        text-align: center;

    }
    .heading{
    
        text-align: center;
        font-size: 1.4rem;
    
    }
    #new-pm25-container{

        margin: 4px;

    }
    .new-value{

        font-size: 1.9rem;

    }
    .new-type{

        text-transform: uppercase;
        font-size: 1.5rem;

    }
    #time{

        font-size: 0.95rem;

    }
    .new-unit{

        font-size: 1.5rem;
        font-weight: 300;

    }
    #new-pm25-image{

        padding-top: 2%;
        width: 15%;

    }
    .heading-container{

        height: 10%;

    }
    .Major_Details{
        
        height: 35%;
        flex-direction: column-reverse;
    
    }
    .Major_Details > *{

        margin: 1%

    }
    #major-res p{

        font-size: 1rem;
        text-align: center;
        margin: 0;

    }
    #test-res p{

        font-size: 1.5rem;
        text-align: center;
        margin: 0;

    }
    #aqi-res-display{
        
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
    }
    #aqi-display{
    
        display: flex;
        padding: 0;
        box-shadow: none;
        border-radius: 40px;
        height: 55%;
        margin: 0 10px;

    }
    #holder-original{

        width: 25vh;
        height: 10vh;

    }
    #alt-x > p{

        font-size: 2.3rem;

    }
    #aqi-display div{

        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;

    }
    #holder{

        width: 20vh;
        border-radius: 35px;
        height: 9vh;

    }
    #test{
        height: 12vh;
        width: 11.8vh;
        left: -2rem;
    }
    #aqi-display div section{
        
        font-size: 2rem;
        font-weight: 500;
        
    }

    #actual_data{

        font-size: 2.8rem !important;
        font-weight: 700 !important;
        padding: 5% 0 !important;
        margin-bottom: 0;

    }

    #res{

        box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
        border-radius: 10px;

    }

    #res p{

        font-size: 1.3rem;
        font-weight: 600;

    }
    
    #temp-humid-display{
    
        height: 35%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border: none;
        box-shadow: none;
    
    }
    #temp-display,#humid-display {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    #temp, #humidity{

        font-size: 1.1rem;

    }

    #temp_icon,#humid_icon{

        width: 20%;

    }

    .Minor_Details{

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 55%;

    }

    #pm25_container{

        box-shadow: none;
        border: none;

    }

    #pm25_img{

        margin: 0;
        margin-left: 1%;
        width: 20%;

    }

    #left-hand,#right-hand{

        width: 96%;
        padding: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }
    .type{

        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 400 !important;
        margin: 0;

    }
    .val{

        font-size: 1.5rem;
        margin: 0;

    }

    .unit{

        font-size: 1rem;
        margin: 0;

    }

    .color{

        display: none;
        margin: auto;
        height: 80%;
        width: 8%;
        background-color: #0b9c17;
        border-radius: 10px;

    }
    #paging-time{

        font-size: 1.2rem;
        text-align: center;

    }
    #colabIcon{

        height: auto;
        width: 10%;

    }
    #partnerIcon{

        width: 20%;
        height: auto;

    }
}
@media screen and (min-width: 1920px) {
    
    #new-heading-container>h2{

        padding: 1%;
        font-size: 2.5rem;

    }
    #holder-original{

        height: 10vh;
        width: 45%;
        border-radius: 60px;

    }
    #holder{

        height: 8vh;
        width: 22vh;
        border-radius: 60px;

    }
    #aqi-display{

        height: 50%;
        border-radius: 45px;

    }
    #outdoor{

        width: 16%;

    }
    #test-original{

        left: -3.5rem;

    }
    #test{
        width: 12vh;
        left: -3.5rem;
    }
    .new-Major-Details > *{

        margin: 0 auto;

    }
    #new-aqi-res-display{

        width: 100%;

    }
    #colabIcon,#partnerIcon{

        width: 10%;
        height: auto;

    }
    #partnerIcon{

        height: auto;
        width: 18%;

    }
    #partnerText{

        font-size: 0.9rem;

    }
    #new-pm25-image{

        width: 13%;

    }
    #footer{

        height: 10%;
        padding: 1%;

    }

    #aqi_scale_img{

        width: 55%;

    }
}
@media screen and (min-width: 2560px) {
    .parent{
        
        width: 100%;

    }
    .paging-heading-container{

        height: 5%;
        margin-bottom: 2%;

    }
    .paging-heading{

        font-size: 3rem;
        font-weight: 500 !important;
        margin: 0;

    }
    .paging-major{

        height: 40%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

    }
    #paging-temp-humid-display{

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

    }
    #paging-temp_icon,#paging-humid_icon{

        width: 30%;

    }
    #paging-temp,#paging-humidity{

        font-size: 2.5rem;

    }
    #paging-aqi-res-display{

        margin: 1%;

    }
    #paging-aqi-display{

        width: 75%;
        margin-right: 1%;

    }
    #paging-res{

        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }
    #paging-condition-icon{

        width: 55%;

    }
    #paging-res > p{

        font-size: 2.5rem;

    }
    #holder{

        height: 8.7vh;
        width: 20vh;
        border-radius: 60px;

    }
    #outdoor{

        width: 15%;

    }
    #x > section{

        font-size: 1.5rem;
        text-align: center;

    }
    #x > p{

        font-size: 3.5rem;

    }
    #alt-x > section{

        font-size: 1.5rem;

    }
    #alt-x > p{

        font-size: 4rem;
        font-weight: 800;

    }
    #test{

        width: 12vh;
        left: -2.5rem;

    }
    .paging-minor{
        
        height: 50%;
        
    }
    #paging-pm25-img{

        width: 80%;

    }
    .paging-type,.paging-unit{

        font-size: 2rem;

    }
    .paging-unit{

        text-align: center;

    }
    .paging-val{

        font-size: 3.2rem;
        text-align: center;

    }
    .heading-container{

        height: 5%;

    }
    .heading{

        font-size: 2.5rem;
        margin: 0;

    }
    .Major_Details{

        height: 50%;
        flex-direction: column-reverse;
        justify-content: space-evenly;

    }
    #new-temp{

        font-size: 2.3rem;

    }
    #temp-humid-display{

        width: 100%;
        display: flex;
        justify-content: space-evenly;
        box-shadow: none;

    }
    #temp-display,#humid-display{

        display: flex;
        justify-content: space-evenly;
        align-items: center;

    }
    #new-pm25-image{

        width: 12%;

    }
    #temp_icon,#humid_icon{

        width: 20%;

    }
    #temp,#humidity{

        font-size: 2rem;

    }
    #aqi-res-display{

        width: 100%;
        justify-content: space-between;

    }
    #aqi-display{

        border-radius: 90px;
        height: 40%;

    }
    #aqi-display div {

        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

    }
    #aqi-display div section{

        font-size: 2rem;
        font-weight: 600;

    }
    #major-res p{

        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;

    }
    #test-res p{

        text-align: center;
        font-size: 2rem;
        font-weight: 600;

    }
    #actual_data{

        font-size: 4rem !important;
        margin: 0;
        font-weight: 400 !important;

    }
    .Minor_Details{

        flex-direction: column;
        height: 45%;

    }
    #pm25_container{

        box-shadow: none;

    }
    #left-hand,#right-hand{

        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 98%;

    }
    #left-hand{

        padding: 0;

    }
    #pm25_img{

        margin: 0;
        margin-left: 1%;
        width: 20%;

    }
    .type{

        font-size: 1.8rem;
        font-weight: 500;

    }
    .val{

        font-size: 2.2rem;

    }
    .unit{

        font-size: 1.8rem;

    }
    #time{

        font-size: 1.5rem;

    }
    #partnerText, #colabText, #pmessage{

        font-size: 1.7rem;

    }
    #paging-time{

        font-size: 2.5rem;
        text-align: center;

    }

}
@media screen and (min-width: 3800px) {
    
    #city,#time{

        font-size: 2.5rem;

    }
    #holder-original{

        height: 8vh;
        width: 40%;
        border-radius: 80px;

    }
    #test-original{

        height: 12.5vh;
        width: 12vh;
        left: -5.5rem;

    }
    #aqi-display div section{

        font-size: 3.5rem;

    }
    #actual_data{

        font-size: 6rem !important;
        font-weight: 600 !important;

    }
    .new-Major-Details > *{

        margin: 0 auto;

    }
    #new-aqi-res-display{

        width: 100%;

    }
    #new-temp-humid-display{

        border-radius: 35px;

    }
    .type{

        font-size: 3rem;

    }
    .val, .unit{

        font-size: 2.5rem;

    }
    #temp, #humidity{

        font-size: 2.5rem;

    }
    #condition-icon{

        width: 40%;

    }
    #major-res p{

        font-size: 2rem;

    }
    #temp_icon, #humid_icon{

        width: 25%;

    }
    #holder{

        width: 20vh;
        height: 8vh;
        border-radius: 80px;

    }
    #test{

        left: -3.8rem;

    }
    #paging-temp-humid-display,#paging-aqi-display,#paging-res{

        border-radius: 35px;

    }
    #paging-pm25{

        border-radius: 30px;

    }
    #test-res,#new-aqi-display{

        border-radius: 35px;

    }
    #new-pm25-container{

        border-radius: 25px;

    }
    #test-res p{

        font-size: 3rem;
        font-weight: 500;

    }
    #new-pm25-image{

        width: 15%;

    }
    .new-value{

        font-size: 3rem;

    }
    .new-unit, .new-type{

        font-size: 3rem;

    }
    #partnerIcon, #colabIcon{

        height: auto;
        width: 15%;

    }

}

#loading img{

    width: 30%;
    position: absolute;
    top: 35%;
    left: 35%;

}