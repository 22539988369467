.scrolling-wrapper{

    color: white;
    background-color: black;

}
.aqi-holder{

    display: flex;
    justify-content: space-evenly;
    margin: 0;

}
.location-name{

    text-align: center;
    color: white;
    padding: 5px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;

}
.scrolling-section {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    text-align: center;
    color: white;
    animation: scrollUp 5s linear infinite 5s;
}
.scrolling-section p{

    margin: 0;

}
@keyframes scrollUp {
    0% { transform: translateY(0%); }
    100% { transform: translateY(-400%); }
}