p{
    font-size: 8px;
}
.smart-wrapper{

    background-color: black;
    color: white;

}
.smart-header{

    padding: 1%;
    height: 11vh;
    display: flex;
    justify-content: space-between;

}
.smart-body{ 

    height: 75vh;

}
.smart-major{

    height: 65%;
    display: flex;
    justify-content: space-around;
    padding: 2%;

}
.major-location-container{

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 35%;

}
.smart-temp-humid-container {
    display: flex;  
    justify-content: center;
    align-items: center;  
}
.smart-temperature-container,
.smart-humidity-container {

    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}
.temp-text,.humid-text{

    font-size: 9px; 
    text-align: center;

}
.humid-icon{

    width: 20%;

}
.temperature-icon{

    width: 17%;

}
.smart-aqi{

    width: 25%;
    text-align: center;

}
.aqi-value-container{

    height: 80%;
    /* border: 2px solid green; */
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

}
.aqi-status{

    font-size: 10px;

}
.aqi-text{

    font-size: 13px;
    font-weight: 600;

}
.aqi-value{

    font-size: 16px;
    font-weight: 700;

}
.aqi-status-image-container{

    width: 32.5%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.aqi-condition-image{

    width: 80%;

}
.smart-minor{ 

    height: 35%;
    border-top: 1px solid white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

}
.smart-footer{

    border-top: 1px solid white;
    height: 14vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;

}
.footer-content{

    padding: 2%;
    width: 80%;

}